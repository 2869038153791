import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import InViewMonitor from 'react-inview-monitor'

import SingleProjectFeaturedImg from '../components/SingleProjectFeaturedImgComp'
import OffsetImage from '../components/OffsetImageComponent'
import Gallery from '../components/Gallery'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FooterSr from '../components/FooterSr'

import allSvgLink from '../img/images.svg'
import Favicon from '../favicon-32x32.png'

const ProjectPost = ({ data, transitionStatus, entry, exit }) => {
  const { wpProjectPostType: project } = data

//   console.log('project')
//   console.log(project)
  const projectGalleryObject =
    project.acfSingleProjectGallery.singleprojectgallery

  //   const imageBefore = project.acf.imageBeforeElement
  const imageAfter = project.acfBeforeAfterImages.imageafterelement
  const GalleryItems = Object.keys(projectGalleryObject)

  //   const featuredImageSrcUrl = project.featured_media.localFile.childImageSharp.original.src
  const featuredImageSrcUrl = project.featuredImage.node.localFile.childImageSharp.original.src

  const imagesOrientationForLightbox = GalleryItems.map((items, keys) =>
    projectGalleryObject[items].mediaDetails.width /
      projectGalleryObject[items].mediaDetails.height <=
    1.3333
      ? { orientation: `vertical` }
      : { orientation: `horizontal` }
  )

  const gayArrow = `<use xlink:href=${allSvgLink}#gay_arrow_right_1 />`
  return (
    <div>
      <Navbar />
      <div className="singlPrjWrap">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${project.title} | Interior Design Studio`}</title>
          <link
            rel="canonical"
            href={`https://ananenadovic.com/${project.slug}`}
          />

          <meta name="description" content={`${project.content}`} />
          <meta name="robots" content="index" />
          <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
          <meta
            property="og:title"
            content={`${project.title} | Interior Design Studio`}
          />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://ananenadovic.com/${project.slug}`}
          />
          <meta property="og:image" content={featuredImageSrcUrl} />

          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:title"
            content={`${project.title} | Interior Design Studio`}
          />
          <meta name="twitter:description" content={`${project.content}`} />
          <meta name="twitter:image" content={featuredImageSrcUrl} />
        </Helmet>
        <InViewMonitor
          classNameNotInView="vis-hidden"
          classNameInView="animated fadeInUp leRelative"
        >
          <SingleProjectFeaturedImg featuredImageMedia={featuredImageSrcUrl} />
        </InViewMonitor>

        <OffsetImage
          content={project.content}
          title={project.title}
          afterImageMedia={imageAfter.localFile.childImageSharp.original.src}
          projectDateText={project.projectOneliner.projectdatetext}
          slug={project.slug}
		  locale={project.locale.locale}
        />
        <Gallery
          title={project.title}
          images={GalleryItems.map((items, keys) => ({
            src: projectGalleryObject[items].localFile.childImageSharp.original.src,
            thumbnail: projectGalleryObject[items].localFile.childImageSharp.original.src,
            imagesOrientationForLightbox,
            width: 1280,
            useForDemo: true,
          }))}
        />
        <div className="endElement backAndContent">
          {data.wpProjectPostType.locale.locale === 'en_US' ? (
            <AniLink
              className="catButton single_project_content back_to_projects offsetImg"
              fade
              to="/projects/"
            >
              {/* <svg dangerouslySetInnerHTML={{ __html: gayArrow }} /> */}
              Back to projects
            </AniLink>
          ) : (
            <AniLink
              className="catButton single_project_content back_to_projects offsetImg"
              fade
              to="/sr/projects/"
            >
              <svg dangerouslySetInnerHTML={{ __html: gayArrow }} />
              Nazad na projekte
            </AniLink>
          )}
          {/* <AniLink
            className="catButton single_project_content back_to_projects offsetImg"
            fade
            to="/projects/"
          >
            Back to projects
          </AniLink> */}
        </div>
        <div className="stickTheFooter">
		{data.wpProjectPostType.locale.locale == 'en_US' ? (
          <Footer />
		):(<FooterSr />)}
        </div>
      </div>
    </div>
  )
}

ProjectPost.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProjectPost

export const pageQuery = graphql`
  query ($id: String!) {
    wpProjectPostType(id: { eq: $id }) {
      id
      slug
      content
      link
      status
	  title
      featuredImage {
        node {
          id
          mediaItemUrl
		  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
          mediaDetails {
            width
            height
            file
          }
        }
      }
      locale {
        locale
      }
      projectOneliner {
        projectdatetext
        projectoneliner
      }
      acfBeforeAfterImages {
        imagebeforeelement {
          altText
          id
          slug
          title
          caption
          mediaItemUrl
		  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
          mediaDetails {
            height
            width
            file
          }
        }
        imageafterelement {
          altText
          id
          slug
          title
          caption
          mediaItemUrl
		  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
          mediaDetails {
            height
            width
            file
          }
        }
      }
      acfSingleProjectGallery {
        singleprojectgallery {
          id
          mediaItemUrl
		  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
          mediaDetails {
            width
            height
            file
          }
          altText
          sourceUrl
          mediaType
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
