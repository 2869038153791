import React, { Component } from 'react'
import PropTypes from 'prop-types'
import posed, { PoseGroup } from 'react-pose'
import Zoom from 'react-medium-image-zoom'
import AniLink from 'gatsby-plugin-transition-link'
import allSvgLink from '../img/images.svg'
import 'react-medium-image-zoom/dist/styles.css'

const faceSvg = `<use xlink:href=${allSvgLink}#icon_facebook_small />`
const twitterSvg = `<use xlink:href=${allSvgLink}#icon_twitter_small />`
const linkedinSvg = `<use xlink:href=${allSvgLink}#icon_linkedin />`

const BoxOffsetImage = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: 'spring', stiffness: 100, damping: 100 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
})
const BoxOffsetContent = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: 'spring', stiffness: 100, damping: 100 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
})

class OffsetImage extends Component {
  state = { isVisible: false }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: !this.state.isVisible,
      })
    }, 200)
  }

  componentWillUnmount() {
    clearTimeout()
  }

  render() {
    const { isVisible } = this.state
    // const { title, id, slug, content, afterImageMedia } = this.props
    const { title, content, afterImageMedia, slug, projectDateText, locale } =
      this.props
    const gayArrow = `<use xlink:href=${allSvgLink}#gay_arrow_right_1 />`

    return (
      <section className="section backAndContent imageAndText">
        <PoseGroup>
          {isVisible && [
            <BoxOffsetImage className="boxOffsetImage" key="boxOffsetImage">
              <div className="single_hero">
                {/* <img src={afterImageMedia} alt="" /> */}
                <Zoom zoomMargin={40}>
                  <img src={afterImageMedia} className="img" />
                </Zoom>
                {/* <Zoom
                  image={{
                    src: afterImageMedia,
                    alt: 'Golden Gate Bridge',
                    className: 'img',
                    style: { maxWidth: '100%' },
                  }}
                  shouldRespectMaxDimension
                /> */}
              </div>
            </BoxOffsetImage>,
            <BoxOffsetContent
              className="boxOffsetContent"
              key="boxOffsetContent"
            >
              <div className="offset_content_hero">
                <div className="theProjectTitleHolder titleAndContent">
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                  <h4 dangerouslySetInnerHTML={{ __html: projectDateText }} />
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                  <div className="endElement shareIt">
                    <a
                      href={`https://twitter.com/intent/tweet/?text=${title}&url=https://ananenadovic.com/projects/${slug}%2F&via=ananenadovic`}
                      target="_blank"
                    >
                      <svg
                        className="twitterSvg"
                        dangerouslySetInnerHTML={{ __html: twitterSvg }}
                      />
                    </a>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=https://ananenadovic.com/projects/${slug}`}
                      target="_blank"
                    >
                      <svg
                        className="faceSvg"
                        dangerouslySetInnerHTML={{ __html: faceSvg }}
                      />
                    </a>
                    <a
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=https://ananenadovic.com/projects/${slug}&title=${title}&source=${title}`}
                      target="_blank"
                    >
                      <svg
                        className="linkedinSvg"
                        dangerouslySetInnerHTML={{ __html: linkedinSvg }}
                      />
                    </a>
                  </div>
                </div>
                {locale === 'en_US' ? (
                  <AniLink
                    className="catButton single_project_content back_to_projects offsetImg"
                    fade="true"
                    to="/projects/"
                  >
                    <svg dangerouslySetInnerHTML={{ __html: gayArrow }} />
                    Back to projects
                  </AniLink>
                ) : (
                  <AniLink
                    className="catButton single_project_content back_to_projects offsetImg"
                    fade="true"
                    to="/sr/projects/"
                  >
                    <svg dangerouslySetInnerHTML={{ __html: gayArrow }} />
                    Nazad na projekte
                  </AniLink>
                )}
              </div>
            </BoxOffsetContent>,
          ]}
        </PoseGroup>
      </section>
    )
  }
}

OffsetImage.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  content: PropTypes.string,
  afterImageMedia: PropTypes.string,
  projectDateText: PropTypes.string,
}

export default OffsetImage
